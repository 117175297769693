import {useState} from 'react';

import Cookies from 'js-cookie';

export const useColor = (community_name) => {
  const initializeColor = () => {
    const colorList = [
      'theme_green',
      'theme_red',
      'theme_yellow',
      'theme_blue'
    ];
    const randomColor = colorList[Math.floor(Math.random() * 4)];

    if (
      Cookies.get('drund_' + community_name + '_accept_cookies') &&
      !sessionStorage.getItem('drundpress_theme')
    ) {
      sessionStorage.setItem('drundpress_theme', randomColor);
    }

    const newColor = Cookies.get('drund_' + community_name + '_accept_cookies')
      ? sessionStorage.getItem('drundpress_theme')
      : randomColor;

    return newColor;
  };

  const [color, setColor] = useState(initializeColor());

  return {
    color,
    setColor
  };
};
