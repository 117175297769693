import React from 'react';

export default function Footer({
  mobile_preview,
  footer,
  community_name,
  toggleModal
}) {
  if (mobile_preview) {
    return null;
  }

  let footerNavJSX = footer.nav_list.map((item) => {
    return (
      <li key={item.id} className="mr-4 mb-3">
        <a className="footer_item" href={item.link}>
          {item.title}
        </a>
      </li>
    );
  });

  const clickContact = (e) => {
    e.preventDefault();
    toggleModal(true);
  };

  return (
    <React.Fragment>
      <footer className="p-5">
        <ul className="footer-list d-flex flex-wrap list-unstyled mb-4 justify-content-center">
          {footerNavJSX}

          <li className="mr-4 mb-3">
            <a
              className="footer_item contact_trigger contact_link"
              href="#"
              onClick={clickContact}
            >
              Contact Us
            </a>
          </li>

          <li className="mr-4 mb-3">
            <a
              className="footer_item"
              href="https://support.drund.com/hc/en-us"
              target="_blank"
              rel="noreferrer"
            >
              Support
            </a>
          </li>
        </ul>
        <div className="description mb-4 footer_item text-center">
          Copyright {new Date().getFullYear()} {community_name}. All rights
          reserved.
        </div>
      </footer>
    </React.Fragment>
  );
}
