import React from 'react';
import * as Sentry from '@sentry/react';

export default function SentryTest() {
  // Button to test sentry
  return (
    <a
      className="d-flex align-items-center"
      onClick={() => {
        // eslint-disable-next-line no-console
        console.log('REPORTING TO SENTRY');
        Sentry.captureMessage('Break the world');
      }}
    >
      Break the world
    </a>
  );
}
