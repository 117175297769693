import React from 'react';

export default function GenericCookies({
  changeModalContent,
  handleAcceptCookie
}) {
  return (
    <div className="cookie_content_area m-4">
      <div className="cookie_header d-flex align-items-center mb-4">
        <svg
          viewBox="0 0 32 32"
          className="svg-icon svg-icon-24x24 icon-ic_cookies"
        >
          <use href="#icon-ic_cookies"></use>
        </svg>
        <h6 className="text-uppercase mb-0">Cookies</h6>
      </div>
      <div className="cookie_message mb-4">
        We’ll use cookies to improve and customize your experience if you
        continue to browse. You can read more about how we use cookies in our
        pages at{' '}
        <a href="https://team.drund.com/page/cookies/">
          drund.com/page/cookies
        </a>
        . Is it okay if we also use cookies to show you personalized ads?
      </div>
      <div className="cookie_button_area d-flex justify-content-end">
        <a
          href="#"
          onClick={changeModalContent}
          className="manage_cookies mr-4 d-flex align-items-center text-uppercase"
        >
          <span className="h6 black mb-0 mr-1">Manage Cookies</span>
        </a>
        <a
          href="#"
          onClick={handleAcceptCookie}
          className="accept_cookies cookie_button theme-3_button d-flex align-items-center text-uppercase"
        >
          <span className="h6 black mb-0 mr-1">Yes, I Accept</span>
          <svg
            viewBox="0 0 32 32"
            className="svg-icon svg-icon-24x24 cookie_button_arrow icon-ic_arrow_right"
          >
            <use href="#icon-ic_arrow_right"></use>
          </svg>
        </a>
      </div>
    </div>
  );
}
