import React from 'react';
import ReactDom from 'react-dom';
import {useClickOutside} from '../../../../hooks/useClickOutside';

import './Modal.css';

export default function Modal({open, children, color, title, onClose}) {
  let modalRef = useClickOutside(open ? onClose : null);

  if (!open) return null;

  return ReactDom.createPortal(
    <React.Fragment>
      <div className={'modal_overlay contact_overlay ' + color}>
        <div
          ref={modalRef}
          className="modal modal-shadow flex-grow-1 p-4 p-md-5"
        >
          <header className="modal_header modal_wrapper d-flex justify-content-between align-items-center pb-2 mb-0">
            <h6 className="black mb-0 text-uppercase">{title}</h6>
            <a href="#" onClick={onClose} className="contact-close">
              <svg
                viewBox="0 0 32 32"
                className="svg-icon svg-icon-24x24 svg-icon-dark-gray icon-ic_close "
              >
                <use href="#icon-ic_close"></use>
              </svg>
            </a>
          </header>
          <section className="modal_wrapper modal_content pt-4 pt-md-5">
            {children}
          </section>
        </div>
      </div>
    </React.Fragment>,
    document.getElementById('modal-root')
  );
}
