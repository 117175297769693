import {useState} from 'react';

export const useHeader = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDropdownOpen((prevValue) => {
      return !prevValue;
    });
  };

  return {
    dropdownOpen,
    setDropdownOpen,
    toggleDropdown
  };
};
