import React from 'react';

export default function Terms() {
  return (
    <React.Fragment>
      <section className="mb-5">
        <h4 className="mb-5">TERMS OF USE</h4>
        <h6 className="mb-3">MOBILE APPS/WEBSITE TERMS OF USE</h6>
        <p className="mb-3">
          These Terms of Use were last updated on May 23, 2021
        </p>

        <h6 className="mb-5">
          FIRST, AN IMPORTANT MESSAGE: PLEASE READ THESE TERMS AND CONDITIONS OF
          USE (&quot;Terms&quot;, &quot;Terms of Use&quot;, or
          &quot;Agreement&quot;) CAREFULLY BEFORE USING THIS SITE, AS THEY
          AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED
          TO, WAIVERS OF RIGHTS, LIMITATION OF LIABILITY, AND YOUR INDEMNITY TO
          US.{' '}
          <b>
            THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL
            BASIS TO RESOLVE DISPUTES, RATHER THAN COURTS OR JURY TRIALS, AND
            LIMITS THE REMEDIES AVAILABLE IN THE EVENT OF A DISPUTE.
          </b>
        </h6>
        <p>
          These Terms of Use describe the terms and conditions that govern your
          use of the current and future online and mobile websites, platforms,
          services, applications, and networks owned or operated by Drund Ltd.
          (&quot;Drund.com&quot;), a website and online service owned and
          operated by Drund Ltd. (the “Company,” “Drund Ltd.”, “Drund”, “we,”
          “our,” or “us”). This page explains the terms by which you may use our
          service. By accessing or using the Drund service, website,
          applications and software (including any downloadable software and/or
          documentation) provided through or in connection with the service,
          including through a mobile device (“Service”), you signify that you
          have read, understood, and agree to be bound by this Terms of Use
          Agreement including our Privacy Policy and Cookie Statement
          (collectively, the “Agreement”), whether or not you are a registered
          user of our Service. If you are visiting our Service from the European
          Union, you will be presented with these Terms on first access. You
          will be asked to review these Terms, and agree to same by clicking an
          “I Accept” button, and you will not be permitted to access our Service
          until you have completed these actions.
        </p>
        <p>
          Governing Terms. These Terms of Use, along with any additional terms
          and conditions that are referenced herein or that are presented
          elsewhere on the Service in relation to a specific service or feature
          and the Drund Ltd. Privacy Policy, set forth the terms and conditions
          that apply to your use of the Service. By using the Service, you agree
          to comply with all of the terms and conditions hereof. If you do not
          agree to these Terms of Use, you should not access or use the Service.
          As above, if you are visiting our Service from the European Union, you
          will be asked to accept these Terms.
        </p>
        <p>
          (A) Changes to Terms of Use. Drund Ltd. may modify the Terms of Use,
          or any part thereof, or add or remove terms at any time, and such
          modifications, additions or deletions will be effective immediately
          upon posting. Your use of the Service after such posting shall be
          deemed to constitute acceptance by you of such modifications,
          additions or deletions.
        </p>

        <p>
          (B) Changes to Service. Drund Ltd. may change or discontinue any
          aspect, service or feature of the Service at any time, including, but
          not limited to, content, hours of availability, and equipment needed
          for access or use.
        </p>

        <p>
          (C) Registration. You may be given the opportunity to register via an
          online registration form to create a user account (&quot;Your
          Account&quot;) that may allow you to receive information from Drund
          Ltd. and/or to participate in certain features on the Service such as
          certain Interactive Areas. Drund Ltd. will use the information you
          provide in accordance with the Drund Ltd. Privacy Policy. By
          registering you represent and warrant that all information that you
          provide on the registration form is current, complete and accurate to
          the best of your knowledge. You agree to maintain and promptly update
          your registration information on the Service so that it remains
          current, complete and accurate. During the registration process, you
          may be required to choose a password and/or username. You acknowledge
          and agree that Drund Ltd. may rely on this password or username to
          identify you. You shall be responsible for protecting the
          confidentiality of your username(s) or password(s), if any. You are
          responsible for all use of Your Account, regardless of whether you
          authorized such access or use, and for ensuring that all use of Your
          Account complies fully with the provisions of these Terms of Use.
        </p>
        <p>
          (D) Equipment. You are responsible for obtaining and maintaining all
          connectivity, computer software, hardware and other equipment needed
          for access to and use of the Service and all charges related to the
          same.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Use of Our Service</h5>
        <p>
          The Service provides a place for people to communicate with others in
          their work, school, community or other group (each, a “Community”) in
          real time.
        </p>

        <p>
          Subject to the terms and conditions of this Agreement, you are hereby
          granted a non-exclusive, limited, personal license to use the Service.
          We reserve all rights not
        </p>
        <p>
          expressly granted herein in the Service and the Company (as defined
          above). Unless we have agreed otherwise with respect to a Community,
          we may terminate this license at any time for any reason or no reason.
          The foregoing license is granted subject to your agreement that: (i)
          you will not copy, distribute, or disclose any part of the Service in
          any medium; (ii) you will not alter or modify any part of the Service;
          and (iii) you will otherwise comply with the terms and conditions of
          this Agreement.
        </p>

        <p>
          You will need to register with the Company and create a “Member
          Account” that is protected with a username and password. If you are a
          Founder (as defined below), you will register as a Member and as a
          Community Administrator. Your account gives you access to the Service
          and functionality that we may establish and maintain from time to time
          and in our sole discretion. You agree to provide us with accurate and
          complete information when You register for an account. You agree to
          keep Your password and other account details secret, and not share
          them with anyone else, in order to prevent unauthorized access to your
          account. If Your contact information or other account information
          changes, You must update your account details promptly. The
          information you provide is subject to the terms of our{' '}
          <a href="/page/privacy-policy/">Privacy Policy.</a>
        </p>
        <p>
          A Community may be created by a person or by legal entity such as a
          school, business, organization or other group (a “Founder”). If You
          obtain access to the Service through a Founder, You acknowledge and
          agree that other Members may have been designated to access, control
          or manage any information or content that is protected by Your
          account. You, not the Company, are solely responsible for access to,
          content in or sharing and use of Your account. We are not liable for
          any loss or damage arising from any access to, content in, or sharing
          and use of Your account. If You believe there has been unauthorized
          access to Your account, You must notify us immediately at
          support@drund.com.
        </p>
        <p>
          To the fullest extent permitted by applicable laws, we will not be
          liable for your losses caused by any unauthorized use of your account,
          and you shall be liable for the losses of the Company or others due to
          your unauthorized use of the account of any other Member. By way of
          non-exhaustive examples, and for the avoidance of doubt, visitors from
          the State of California and from within the European Union will have
          certain rights in relation to the personal information that we hold
          about them. Please see our Privacy Policy for more information.
        </p>

        <p>
          You may use your Account Settings to control your Member Profile. We
          will send you Service-related notices, including any notices required
          by law, through the Service, in lieu of communication by postal mail
          or to a separate email account. These messages may include
          notification of changes to features of the Service, and special
          offers. If you do not want to receive such email messages, you may opt
          out by changing the preferences in your Notifications Settings. Opting
          out may prevent you from receiving email messages regarding updates,
          improvements, or offers. Certain corporate, school or other
          Communities have negotiated further modifications to the types of
          emails we may send you. Before sending you promotional e-mails, we
          will always first seek your prior opt-in consent.
        </p>
        <p>
          You agree not to use or launch any automated system, including without
          limitation, “robots,” “spiders,” “offline readers,” etc., that
          accesses the Service in a manner that sends more request messages to
          the Company’s servers than a human can reasonably produce in the same
          period of time by using a conventional on-line web browser. You agree
          not to collect or harvest any personally identifiable information,
          including account names, from the Service nor to use the communication
          systems provided by the Service for any commercial solicitation
          purposes. You agree not to use any portion of the Service as a
          destination linked from any unsolicited bulk messages or unsolicited
          commercial messages.
        </p>
        <p>
          We may permanently or temporarily terminate, suspend, or otherwise
          refuse to permit your access to the Service, and/or delete User
          Content you have posted, all without notice and liability, if we
          determine (in our sole discretion), you have violated any part of this
          Agreement, including, but not limited to, the following prohibited
          actions: (i) you create risk or possible legal exposure for us (ii)
          attempting to interfere with, compromise the system integrity or
          security or decipher any transmissions to or from the servers running
          the Service; (iii) taking any action that we determine, in our sole
          discretion, imposes, or may impose an unreasonable or
          disproportionately large load on our infrastructure; (iv) uploading
          invalid data, viruses, worms, or other software agents through the
          Service; (v) impersonating another person or otherwise misrepresenting
          your affiliation with a person or entity, committing fraud, hiding or
          attempting to hide your identity; (vi) interfering with the proper
          working of the Service; (vii) bypassing the measures we may use to
          prevent or restrict access to the Service, including, but not limited
          to, registering for the Service with a non-Community email address;
          (viii) reverse engineer, decompile, disassemble, modify or create
          derivative works of the Service, (ix) alter or modify any disabling
          mechanism which may be included in the Service, (x) assign,
          sublicense, rent, timeshare, loan, lease or otherwise transfer the
          Service, (xi) directly or indirectly permit any third party to copy
          the Service, (xii) remove or fail to reproduce on any permitted backup
          or archival copy of the Service any proprietary notices (e.g.,
          copyright and trademark notices) from the Service, (xiii) infringing
          the intellectual property or other legal rights of third parties; or
          (xiv) engaging in abusive behavior, such as, without limitation, (a)
          posting a large quantity of unsolicited comments, especially in an
          attempt to advertise a service or link, (b) promoting third party
          sites, (c) creating serial accounts, or (d) posting or linking to
          third party sites which contain malicious software or which are
          intended to disrupt the device or account of another Member. Upon
          termination of your use of the Service for any reason, you continue to
          be bound by this Agreement.
        </p>
        <p>
          We reserve the right to offer alternative and/or additional services
          to Founders and to certain administrative users, including Community
          or Group Administrators (as defined below), that may not be offered to
          general Users. You acknowledge that we may charge a fee for the use of
          any Service, provided that we notify you of any such fee before you
          incur it. Subject to the foregoing, you agree to pay any fees incurred
          by you. In the event that you have elected to receive additional
          Service for a fee, and you fail to pay such fee when due, then in
          addition to all other remedies available to the Company, We may
          immediately cease providing all such additional Service.
        </p>

        <p>
          You are solely responsible for ensuring that Your use of the Service
          is in compliance with all applicable domestic, foreign, federal, state
          and local laws, rules and regulations. You shall defend, indemnify and
          hold the Company, its officers, directors, shareholders, affiliates,
          suppliers and licensors, harmless from and against any and all
          damages, fines, penalties, assessments, liabilities, losses, costs and
          expenses (including attorneys’ fees, expert fees and out-of-pocket
          expenses) in connection with: Your use of the Service; Your violation
          of this Agreement; Your violation of any third-party rights, including
          any intellectual property rights; any claims that the Service or any
          part thereof were exported or otherwise shipped or transported by You
          in violation of applicable laws, rules and regulations; or any claim
          of misuse of the Service, including but not limited to any claim that
          You are storing illegal files or data in Your Account.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Subscription Fees</h5>
        <p>
          Drund Annual Fee. The Company may, from time to time in its sole
          discretion, charge an annual fee for the right to use the Service (the
          “Annual Fee”). The Annual Fee may apply to all or any determinable
          class or category of Communities and/or Members, and the Annual Fee
          may be a flat fee, a per-User fee, or some other method of calculation
          determined by the Company; provided however, any Annual Fee will be
          published by the Company and will be uniformly applicable to all
          Members or participants in the applicable class or category determined
          by the Company.
        </p>
        <h3>User Content and Conduct; Community Guidelines</h3>

        <p>
          The following terms apply to content submitted by users, and user
          conduct, on the Service&apos;s Interactive Areas:
        </p>

        <p>
          (A) Interactive Areas. The Service may contain comments sections,
          discussion forums, or other interactive features, Drund Ltd.&apos;s
          user-generated news community (&quot;Interactive Areas&quot;) in which
          you may post or upload user-generated content, including but not
          limited to, comments, video, photos, messages, other materials or
          items (collectively, &quot;User Content&quot;). You are solely
          responsible for your use of any Interactive Areas and you use them at
          your own risk. Interactive Areas are available for individuals aged 13
          years or older. By submitting User Content to an Interactive Area, you
          represent that you are 13 years of age or older and, if you are under
          the age of 18, you either are an emancipated minor, or have obtained
          the legal consent of your parent or legal guardian to enter into these
          Terms of Use, submit content, participate on the Service, and fulfill
          the obligations set forth in these Terms of Use, which forms a binding
          contract between you and Drund Ltd. Employees of Drund Ltd., may not
          submit User Content without permission from their supervisors.
        </p>
        <p>
          Some areas of the Service may allow Founders and/or other Members to
          post video, photographs, and writings, feedback, comments, questions,
          data, and other information (“User Content”). The User who posts User
          Content is solely responsible for User Content that she uploads,
          publishes, displays, links to or otherwise makes available
          (hereinafter, “post”) on the Service, and the consequences of posting
          or publishing it.
        </p>
        <p>
          We do not monitor or control the User Content posted via the Service
          and we cannot take responsibility for such User Content. Any use or
          reliance on any User Content or materials posted via the Service or
          obtained by you through the Service is at your own risk. Absent a
          Community Administrator, all User Content is owned by the User who
          posted it to the Service, although each User acknowledges and agrees
          that upon the introduction of a Community Administrator into that
          User’s Community, all related User Content will automatically become
          the property of the Founder of the Community or Community
          Administrator without any notice to Members of that Community. If a
          Community has a Community Administrator, all User Content is the
          property of the Community Administrator (including all User Content
          posted to a specific Group or Groups within a Community and all User
          Content created prior to the existence of the Community
          Administrator). In either case, we do not have, nor do we claim, any
          ownership rights in any User Content. In addition, you should note
          that if you are no longer a Member of a Community, your access to all
          User Content you uploaded may be terminated, regardless of whether the
          Community has a Community Administrator. Once a User is removed from a
          Community, the content of that User remains on the Community and is
          the sole property of the Founder of that Community.
        </p>
        <p>
          Notwithstanding the foregoing, by submitting, posting or displaying
          User Content on or through the Service, you grant us a worldwide,
          non-exclusive, royalty-free license (with the right to sublicense) to
          use, copy, reproduce, process, adapt, modify, publish, transmit,
          display and distribute your User Content in any and all media or
          distribution methods (now known or later developed). You agree that
          this license includes the right for us to provide, promote, and
          improve the Service and to make User Content submitted to or through
          the Service available to other companies, organizations or individuals
          who work with the Company. for the syndication, broadcast,
          distribution or publication of such Content on other media and
          services, subject to the restrictions set forth in these Terms of Use
          and our Privacy Policy.
        </p>

        <p>
          We may modify or adapt your User Content in order to transmit, display
          or distribute it over computer networks and in various media and/or
          make changes to your User Content as is necessary to conform and adapt
          that User Content to any requirements or limitations of any networks,
          devices, services or media.
        </p>

        <p>
          You agree, with respect to User Content that you post, that the
          Company has the following rights: (i) the right to publish, re-publish
          and distribute any User Content (and portions thereof) an unlimited
          number of times on all platforms or by all means now known or
          hereafter available within the Community in which it was originally
          posted; (ii) the rights to receive, edit, process, compile, transcode,
          re-format all User Content for use in the Community of which the User
          is a part (“Process”); (iii) subject to the Company obtaining any
          required consent(s) by third party distributors (e.g., broadcasters),
          the Company shall have the right, but not the obligation, to receive
          any User Content from such third party source and to Process such User
          Content as if it had been delivered by the User; (iv) the right to
          archive, truncate or summarize any User Content and make available
          such content for subsequent searches in perpetuity; (v) the right to
          develop derivative works using User Content and any Other Content for
          publication in one or more Groups within the Community (such
          derivative works may include work (content) based upon one or more
          preexisting works, such as a translation, musical arrangement,
          dramatization, fictionalization, motion picture version, sound
          recording, art reproduction, abridgment, condensation, or any other
          form in which a work that may be recast, transformed, or adapted, and
          which shall be “Other Content” for purposes of this Agreement); (vi)
          the right to excerpt User Content and incorporate such excerpts into
          other works for publication, including, without limitation, original
          content produced and/or published by the Company; and (vii) the right
          to add in the Community into which the User Content was posted a
          direct link (e.g., text links, image links, deep links, links that
          result in framing, and in-lining links) to any website page, digital
          content or other material created, posted or published by User.
        </p>
        <p>
          The Company takes no responsibility and assumes no liability for any
          User Content that you or any other Members or third parties post or
          send over the Service. You understand and agree that any loss or
          damage of any kind that occurs as a result of the use of any User
          Content that you send, upload, download, stream, post, transmit,
          display, or otherwise make available or access through your use of the
          Service, is solely your responsibility. We are not responsible for any
          public display or misuse of your User Content. You understand and
          acknowledge that you may be exposed to User Content that is
          inaccurate, offensive, indecent, or objectionable, and you agree that
          we shall not be liable for any damages you allege to incur as a result
          of such User Content. Under no circumstances will the Company be
          liable in any way for any User Content, including, but not limited to,
          any errors or omissions in any User Content, or any loss or damage of
          any kind incurred as a result of the use of any User Content posted,
          emailed, transmitted or otherwise made available via the Service or
          broadcast elsewhere.
        </p>
        <p>
          You are solely responsible for your interactions with other Members.
          We reserve the right, but have no obligation, to resolve disputes
          between You and other Members in our sole discretion (which resolution
          may include modifying or deleting your User Content without your
          consent, and/or terminating or suspending your participation in one or
          more Groups or Communities and/or terminating or suspending your
          Member account.
        </p>
        <p>
          For the purposes of this Agreement, “Intellectual Property Rights”
          means all patent rights, copyright rights, mask work rights, moral
          rights, rights of publicity, trademark, trade dress and service mark
          rights, goodwill, trade secret rights and other intellectual property
          rights as may now exist or hereafter come into existence, and all
          applications therefore and registrations, renewals and extensions
          thereof, under the laws of any state, country, territory or other
          jurisdiction.
        </p>

        <p>
          (B) Community Guidelines. By submitting any User Content or
          participating in an Interactive Area within or in connection with the
          Service, you agree to abide by the following rules of conduct:
        </p>
        <ul>
          <li>
            You agree not to upload, post or otherwise transmit any User Content
            that:
          </li>

          <li>
            violates or infringes in any way upon the rights of others,
            including any statements which may defame, harass, stalk or threaten
            others.
          </li>

          <li>you know to be false, misleading or inaccurate.</li>

          <li>
            contains blatant expressions of bigotry, racism, racially or
            ethnically offensive content, hate speech, abusiveness, vulgarity or
            profanity.
          </li>

          <li>
            contains or advocates pornography or sexually explicit content,
            pedophilia, incest, bestiality, or that is otherwise obscene or
            lewd.
          </li>
          <li>
            violates any law or advocates or provides instruction on dangerous,
            illegal, or predatory acts, or discusses illegal activities with the
            intent to commit them.
          </li>

          <li>advocates violent behavior.</li>

          <li>poses a reasonable threat to personal or public safety.</li>

          <li>
            contains violent images of killing or physical abuse that appear to
            have been captured solely, or principally, for exploitive, prurient,
            or gratuitous purposes.
          </li>

          <li>
            is protected by copyright, trademark, trade secret, right of
            publicity or other proprietary right without the express permission
            of the owner of such copyright, trademark, trade secret, right of
            publicity or other proprietary right. The burden of determining that
            any User Content is not protected by copyright, trademark, trade
            secret, right of publicity or other proprietary right rests with
            you. You shall be solely liable for any damage resulting from any
            infringement of copyrights, trademarks, trade secrets, rights of
            publicity or other proprietary rights or any other harm resulting
            from such a submission. Any person determined by Drund Ltd., in its
            sole discretion, to have violated the intellectual property or other
            rights of others shall be barred from submitting or posting any
            further material on the Service.
          </li>
          <li>
            does not generally pertain to the designated topic or theme of any
            Interactive Area.
          </li>

          <li>
            contains any unsolicited or unauthorized advertising or promotional
            materials with respect to products or services, &quot;junk
            mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
            &quot;pyramid schemes,&quot; or any other form of solicitation.
          </li>

          <li>
            You agree not to engage in activity that would constitute a criminal
            offense or give rise to a civil liability.
          </li>

          <li>
            You agree that if necessary, you have the consent of each and every
            identifiable natural person in any submission to use such persons
            name or likeness in the manner contemplated by the Service.
          </li>

          <li>
            You agree that any person who appears in your submission who is a
            current member of the Screen Actors Guild (SAG), the American
            Federation of Television and Radio Actors (AFTRA) or any other
            rights society is not entitled to compensation by Drund Ltd.
          </li>

          <li>
            You agree not to impersonate any person or entity, including, but
            not limited to, Drund Ltd. or any Drund Ltd. employee, or falsely
            state or otherwise misrepresent your affiliation with any person or
            entity.
          </li>

          <li>
            You agree not to represent or suggest, directly or indirectly, Drund
            Ltd.&apos;s endorsement of User Content.
          </li>

          <li>
            You agree not to interfere with any other user&apos;s right to
            privacy, including by harvesting or collecting
            personally-identifiable information about the Service users or
            posting private information about a third party.
          </li>

          <li>
            You agree not to upload, post or otherwise transmit any User
            Content, software or other materials which contain a virus or other
            harmful or disruptive component.
          </li>

          <li>
            You agree not to interfere with or disrupt the Service or the
            servers or networks connected to the Service, or disobey any
            requirements, procedures, policies or regulations of networks
            connected to the Service.
          </li>

          <li>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            for any commercial purpose, any portion of the Service, use the
            Service, or access to the Service.
          </li>

          <li>
            You agree not to use any service, technology or automated system to
            artificially inflate the page views that your User Content receives.
            This includes pay-per-click services, web &quot;robots&quot; and any
            other current or future technologies. You also agree not to direct
            any third party to use these services, technologies or automated
            systems on your behalf.
          </li>

          <li>
            You agree not to use any technology, service or automated system to
            post more User Content than an individual could upload in a given
            period of time. You also agree not to direct any third party to use
            these services, technologies or automated systems on your behalf.
          </li>
        </ul>
        <p>
          Any conduct that in Drund Ltd.&apos;s sole discretion restricts or
          inhibits anyone else from using or enjoying the Service will not be
          permitted. Drund Ltd. reserves the right in its sole discretion to
          remove or edit User Content by you and to terminate Your Account for
          any reason.
        </p>

        <p>
          Drund Ltd. does not vouch for the accuracy or credibility of any User
          Content and does not take any responsibility or assume any liability
          for any actions you may take as a result of reading User Content
          posted on the Service. Through your use of Interactive Areas, you may
          be exposed to content that you may find offensive, objectionable,
          harmful, inaccurate or deceptive. There may also be risks of dealing
          with underage persons, people acting under false pretense,
          international trade issues and foreign nationals. By using Interactive
          Areas, you assume all associated risks.
        </p>

        <p>
          (C) Monitoring. Drund Ltd. shall have the right, but not the
          obligation, to monitor User Content posted or uploaded to the Service
          to determine compliance with these Terms of Use and any operating
          rules established by Drund Ltd. and to satisfy any law, regulation or
          authorized government request. Although Drund Ltd. has no obligation
          to monitor, screen, edit or remove any of the User Content posted or
          uploaded to the Service, Drund Ltd. reserves the right, and has
          absolute discretion, to screen, edit, refuse to post or remove without
          notice any User Content posted or uploaded to the Service at any time
          and for any reason, and you are solely responsible for creating backup
          copies of and replacing any User Content posted to the Service at your
          sole cost and expense. In addition, Drund Ltd. may share personally
          identifiable information in response to a law enforcement
          agency&apos;s request, or where we believe it is necessary, or as
          otherwise required or permitted by law. See our Drund Ltd. Privacy
          Policy.
        </p>
        <p>
          The decision by Drund Ltd. to monitor and/or modify User Content does
          not constitute nor shall it be deemed to constitute any responsibility
          or liability in any manner on the part of Drund Ltd. in connection
          with or arising from use by you of Interactive Areas on the Service.
        </p>

        <p>
          (D) Subject to the terms and conditions of this Agreement, you are
          hereby granted a non-exclusive, limited, personal license to use the
          Service. We reserve all rights not expressly granted herein in the
          Service and the Company (as defined above). Unless we have agreed
          otherwise with respect to a Community, we may terminate this license
          at any time for any reason or no reason. The foregoing license is
          granted subject to your agreement that: (i) you will not copy,
          distribute, or disclose any part of the Service in any medium; (ii)
          you will not alter or modify any part of the Service; and (iii) you
          will otherwise comply with the terms and conditions of this Agreement.
        </p>
        <p>
          (E) Moral Rights. If it is determined that you retain moral rights
          (including rights of attribution or integrity) in the User Content,
          you hereby declare that (a) you do not require that any personally
          identifying information be used in connection with the User Content,
          or any derivative works of or upgrades or updates thereto; (b) you
          have no objection to the publication, use, modification, deletion and
          exploitation of the User Content by Drund Ltd. or its licensees,
          successors and assigns; (c) you forever waive and agree not to claim
          or assert any entitlement to any and all moral rights of an author in
          any of the User Content; and (d) you forever release Drund Ltd., and
          its licensees, successors and assigns, from any claims that you could
          otherwise assert against Drund Ltd. by virtue of any such moral
          rights. You also permit any other user to access, view, store or
          reproduce the User Content for that user&apos;s personal use.
        </p>

        <p>
          (F) No Obligation. User Content submitted by you will be considered
          non-confidential and Drund Ltd. is under no obligation to treat such
          User Content as proprietary information except pursuant to the Drund
          Ltd. Privacy Policy. Without limiting the foregoing, Drund Ltd.
          reserves the right to use any User Content as it deems appropriate,
          including, without limitation, deleting, editing, modifying,
          rejecting, or refusing to post it. Drund Ltd. is under no obligation
          to edit, delete or otherwise modify User Content once it has been
          submitted to Drund Ltd. Drund Ltd. shall have no duty to attribute
          authorship of User Content to you and shall not be obligated to
          enforce any form of attribution by third parties.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Community Administration</h5>
        <h3>General</h3>
        <p>
          Initially, the person setting up a Community (the “Founder”) is
          designated the Community Administrator. Each Community may have one or
          more individuals who are responsible for overseeing such Community
          (the “Community Administrator”).
        </p>
        <p>
          Communities may exist on the Service without a Community
          Administrator; in such case Members are responsible for conducting
          themselves in accordance with this Agreement. Certain Groups within a
          Community may also have a Group Administrator (defined below) with
          certain authority that is less than that of a Community Administrator.
        </p>
        <p>
          From time to time, Members may be asked to confirm their account on
          the Community via an email message containing a hyperlink to the
          User’s Community email address. If such account is not reconfirmed,
          the account is removed. Once a User is removed from a Community, the
          content of that User remains on the Community and is the sole property
          of the Community Administrator.
        </p>

        <p>
          Members must follow their particular Community Administrator’s
          policies, guidelines, and procedures concerning the Content they post
          to the Service. Members may delete their own User Content from the
          Community, so long as they are a Member of that Community. Community
          Administrators may delete the User Content of one or more Members in
          their Community.
        </p>

        <p>
          The Founder, Community Administrator and/or individual Members may
          form a group to segment the audience for certain User Content
          (“Group”). Each Group may have one or more administrators (the “Group
          Administrator”), who may also be a Community Administrator. A Group
          may be designated as open to any User in a Community (a “Public
          Group”) or a Group may be limited to certain Members in a Community
          with membership subject to the approval of the Group Administrator (a
          “Private Group”). A Group Administrator may add or remove Members to
          that particular Group. Though Members may establish a Private Group,
          User Content posted within a Private Group on a Community is owned by
          that Community Administrator. The Founder, Community Administrator
          and/or Group Administrator may set criteria or conditions for
          participation in Private Groups, including payment of a one-time or
          periodic subscription or membership fee.
        </p>
        <p>
          If a Founder, Community or Group Administrator wishes to institute a
          subscription or membership fee, they must contact the Company
          (support@drund.com) to enable the subscription fee capability.
        </p>

        <p>
          Community and Group Administrators have the right, but have no
          obligation, to resolve disputes between participants in the Groups or
          Communities they are responsible for in their sole discretion (which
          resolution may include modifying or deleting User Content without
          consent, and/or terminating or suspending participation by one or more
          Members in the Groups or Communities.
        </p>

        <p>Subscription Group Revenue Sharing</p>

        <p>
          Founders, Community Administrators or Group Administrators (“Clients”)
          who enter into Client Service Agreements with the Company may elect,
          in their sole discretion, to charge Members for the right to become a
          Member of a Community or Group within a Community (all such groups or
          communities are hereinafter referred to as “Subscription Groups”). The
          Client Service Agreement may be requested at support.drund.com.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Mobile Use</h5>
        <h3>General</h3>

        <p>
          The Service is designed to be platform agnostic, and may be accessed
          through mobile devices including mobile telephones and tablets. There
          is no additional charge by the Company to access the Service using a
          mobile telephone device. Standard data, text messaging rates and other
          charges from a User’s mobile carrier may apply.
        </p>

        <h3>Opt-in/Opt-out</h3>

        <p>
          By registering your mobile device, you warrant that you are the legal
          owner of the mobile device which you registered to receive mobile
          alerts messages and that you are authorized to incur any charges that
          may result from receiving mobile alert messages.
        </p>

        <p>
          The Company does not warrant that the access to the Service using a
          mobile device will be uninterrupted or error free. We shall not be
          liable for any delay in performing or failure to perform any
          obligation hereunder by circumstances beyond our reasonable control
          including (without limitation) any technical problems such as (for
          example) defects, congestion or failures of capacity or otherwise in
          the public data or telephone or mobile carrier community or caused by
          atmospheric interference, your mobile device being turned off for an
          extended period of time so that messages are not retained, or your
          being unable to obtain mobile community coverage.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Branded Mobile Application</h5>
        <p>
          The Company may, from time to time permit certain institutional
          Founders the opportunity to have the Service accessed on mobile
          devices using a branded application (that is, Members in the Founders’
          Community will be able to download an application for their mobile
          device which is branded to the Community Founder, and through which
          they will be able to access the Community(ies) of the Founder).
        </p>

        <p>
          The Company will notify an institutional Community Founder if it is
          eligible to subscribe to the Branded Application Service, including
          the cost and requirements. Generally, the requirements will include:
          (i) payment of an subscription fee and/or other charges to the
          Company, and (ii) submission of such information as may be required by
          the Company, including the logo and/or other intellectual property of
          the Founder (the “Founder IP”).
        </p>

        <p>
          Upon receipt, the Company will create Android and iOS (mobile phone
          and iPad) applications (the “Founder Branded Applications”) and upload
          them to the Google Play Store and the Apple Store. Members in the
          Founder Communities will then be able to download the Founder Branded
          Applications for their mobile device and access the Founder’s
          Community in the Service. In addition, certain aspects of the
          appearance of the Service will be “customized” to incorporate elements
          of the Founder IP.
        </p>
        <p>
          The Company shall determine the eligibility for Branded Application
          Service, the fees and information required to be provided from time to
          time, in its sole discretion. The Company may discontinue the
          availability of the Branded Application Service at any time, in its
          sole discretion. If the Founder or the Company elects to discontinue
          its Branded Application Service, all User Content will continue to be
          available on the Service by accessing through the Drund site.
        </p>
        <p>
          The following terms and conditions shall apply specifically to
          Founders who elect to subscribe to the Branded Application Service
          (the “Client”): The Company grants to the Client the nontransferable,
          nonexclusive, worldwide right to use the Branded Application Service
          during the Subscription Term (as defined below), subject to all of the
          following additional conditions and restrictions: (i) Client shall not
          license, sell, rent, lease, transfer, assign, distribute, display,
          host, outsource, disclose or otherwise commercially exploit or make
          the Branded Application Service available to any third party other
          than to allow Members to access the Community through the Branded
          Application; (ii) no part of the Branded Application Service may be
          copied, reproduced, distributed, republished, downloaded, displayed,
          posted or transmitted in any form or by any means, including but not
          limited to electronic, mechanical, photocopying, recording, or other
          means; (iii) Client shall not use or access the Branded Application
          Service to build or support, and/or assist a third party in building
          or supporting, products or services competitive to the Service; (iv)
          Client acknowledges and agrees that the Company shall own all right,
          title and interest in and to all intellectual property rights
          (including all derivatives or improvements thereof) in the Branded
          Application Service and any suggestions, enhancement requests,
          feedback, recommendations or other information provided by Client or
          any other party relating to the Branded Application Service; (v)
          Client acknowledges that the Company transfers no rights of ownership
          to Client in the Branded Application Service to Client (provided
          however, the Company shall license to Client those intellectual
          property rights as are directly and unavoidably required by the
          provision of the Branded Application Service); or (vi) any license
          granted to Client herein shall not be transferred nor sub-licensed,
          shall be non-exclusive, royalty free, limited to the Subscription Term
          and granted only for the purpose of allowing Client and its Community
          Members to use the Branded Application to access the Service and
          Client’s Communities as contemplated by these Terms of Use.
        </p>

        <p>
          The Company will provide hosting services for the Branded
          Applications. In connection with providing such hosting services, the
          Company shall use best efforts to provide a delivery of 99.8% monthly
          availability of the Branded Application Service. This will not include
          planned outages or outages directly attributable to the Company’s
          hosting and storage providers.
        </p>

        <p>
          Client agrees to pay in advance for the Branded Application Service
          unless otherwise set forth in an offering of Branded Application
          Service, which offering will include the specific products and
          services to be provided by the Company and the cost therefor (the
          “Client Service Agreement”). All fees payable in connection with the
          Branded Application Service shall be determined by the Company in its
          sole discretion, and are non-cancelable, nonrefundable, and are
          exclusive of all taxes, levies, or duties imposed by any governmental
          authority. Client shall be responsible for payment of all such taxes,
          levies, or duties, even if such amounts are not listed in the Client
          Service Agreement.
        </p>
        <p>
          Client will provide the Company with valid and updated credit card or
          other approved payment method and complete and accurate billing and
          contact information. If Client provides credit card information to the
          Company, Client authorizes the Company to bill such credit card (a) at
          the time that Client orders any products or services, for all products
          and services set forth in the Client Service Agreement, and (b) at the
          time of any renewal, for the amount charged for any renewal
          Subscription Term(s) as set forth herein. If the Company, in its
          discretion, permits Client to make payment using a method other than a
          credit card, the Company will invoice Client at the time of the
          initial Client Service Agreement and approximately one month in
          advance of the start of any renewal or subsequent billing period. All
          amounts invoiced hereunder are due and payable immediately upon
          Client’s receipt of any such invoice. Client agrees to reimburse the
          Company for any costs of collection incurred with respect to
          collecting late payments, including the costs of reasonable attorneys’
          fees. Late payments shall accrue interest at the rate of 1% per month
          commencing with the due date.
        </p>
        <p>
          The term of the Branded Application Service set forth in the Client
          Service Agreement shall commence upon the Effective Date set forth in
          the Client Service Agreement and shall continue for the period of time
          set forth in such Client Service Agreement(the &quot;Subscription
          Term&quot;). Except as otherwise provided in the Client Service
          Agreement, the Branded Application Service may be renewed under the
          Company’s then-current applicable policies and terms, subject to the
          Company’s acceptance and Clients’ payment of fees for such Branded
          Application Service.
        </p>

        <p>
          Client may cancel the Branded Application Service in writing by mail
          or email at any time prior to the renewal date. Client understands
          that under no circumstances will any refund be provided for services
          paid for in advance. In such case where the Branded Application
          Service has been paid for yearly in advance, the Branded Application
          Service will expire at the end of the paid-for term. If the Branded
          Application Service is a monthly billing arrangement, service will
          expire at the end of the month in which the Company is notified in
          writing of the request to cancel. No refunds whatsoever are provided
          for any other charges or fees described in the Client Service
          Agreement.
        </p>
        <p>
          The Company may compile statistical and performance information
          related to the provision of the Branded Application Service, and may
          make such information publicly available. Client hereby agrees to
          permit the Company to make such information publicly available. The
          Company retains all Intellectual Property rights in such information.
        </p>

        <p>
          The Company may compile statistical and performance information
          related to the provision of the Branded Application Service, and may
          make such information publicly available. Client hereby agrees to
          permit the Company to make such information publicly available. The
          Company retains all intellectual property rights in such information.
        </p>

        <p>
          In addition to any other indemnification obligations that Founders may
          have, Founders electing to use the Branded Application Service agree
          to defend, indemnify and hold the Company and its subsidiaries,
          agents, managers, employees, contractors, agents, officers and
          directors harmless from and against any and all claims, demands,
          damages, obligations, losses, liabilities, costs and expenses
          (including, but not limited to legal fees, court costs, and
          professional fees) arising out of use of the Founder IP, including but
          not limited to any allegation that use of the Founder IP by the
          Company to create the Founder Branded Applications, violates or
          infringes upon the rights of any other person, violates any applicable
          federal, state or local law, rule or regulation, or any contract or
          agreement to which the Founder is a party.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Drund Streaming Service</h5>
        <p>
          The Company has developed a feature allowing Users to live stream,
          store and share video (the “Streaming Service”). The Streaming Service
          is not available to all Users.
        </p>

        <p>
          If this feature is enabled within your Community, You are responsible
          for your use of the Streaming Service, for any User Content you post
          to the Service, and for any consequences thereof. The User Content you
          submit, post, or display will be able to be viewed by other users and
          re-published through third party services and websites. You should
          only provide User Content you are comfortable sharing with others
          under these Terms.
        </p>

        <p>
          The Streaming Service may change from time to time without prior
          notice to you. In addition, we may stop (permanently or temporarily)
          providing the Streaming Service (or any features within Streaming
          Service) to you or to users generally and may not be able to provide
          you with prior notice. We also retain the right to create limits on
          use and storage at our sole discretion at any time without prior
          notice to you.
        </p>

        <p>Restrictions on User Content and Use of Streaming Service</p>

        <p>
          You must comply with the following terms when posting User Content to
          the Streaming Service, in addition to the prohibitions and
          restrictions found elsewhere in the Terms of Use:
        </p>

        <ol>
          <li>pornographic or overtly sexual User Content is not permitted.</li>
          <li>
            posting of explicitly graphic User Content or media is not
            permitted. Explicit graphic User Content includes, but is not
            limited to, depictions of child abuse, animal abuse, or bodily harm.
          </li>
          <li>
            posting of User Content that is intended to incite violent, illegal
            or dangerous activities is not permitted. This includes User Content
            that is intended to incite violence, or includes a direct and
            specific threat of violence to others.
          </li>
          <li>
            posting of User Content that is intended or has the effect of
            abusing or harassing another person or is the private, proprietary
            or confidential information of another person is not permitted.
          </li>
          <li>
            posting of User Content which has the effect of impersonating
            another person, or is misleading or deceptive as to the identity of
            the person posting the User Content, is not permitted. Parody,
            commentary, or fan accounts are permitted so long as they are
            clearly labeled.
          </li>
          <li>spamming is not permitted.</li>
        </ol>
        <p>
          We reserve the right to allow sensitive User Content when it is
          artistic, educational, scientific or newsworthy. We may consider the
          context and nature of the information posted, local privacy laws, and
          other case-specific facts when determining if this policy has been
          violated.
        </p>

        <p>
          Some examples of private information that should NOT be posted using
          the Streaming Service include:
        </p>
        <ol>
          <li>
            Social security or driver’s license, or other similar identification
            numbers.
          </li>
          <li>Credit card information.</li>
          <li>
            Addresses or locations that are considered and treated private.
          </li>
          <li>Non-public, personal phone numbers and emails.</li>
          <li>
            Intimate videos or photos taken or distributed without the
            subject&apos;s consent.
          </li>
          <li>
            Videos or images that are considered and treated as private under
            applicable laws.
          </li>
        </ol>

        <p>
          Some examples of abuse and spam that should not be broadcast using the
          Streaming Service include:
        </p>
        <ol>
          <li>purchasing or selling products.</li>
          <li>
            posting for the purpose of directing users to an external site or
            service, or posting deliberately misleading broadcast titles,
            especially with the intent to redirect the viewer to an external
            site or service.
          </li>
          <li>paying to have a broadcast ‘shared’.</li>
          <li>Using serial accounts to auto ‘share’ broadcasts.</li>
        </ol>

        <p>
          We reserve the right at all times (but will not have an obligation) to
          remove or refuse to distribute any User Content on the Streaming
          Service, to suspend or terminate users, and to reclaim usernames
          without liability to you. We also reserve the right to access, read,
          preserve, and disclose any information as we reasonably believe is
          necessary to: (i) satisfy any applicable law, regulation, legal
          process or governmental request; (ii) enforce these Terms of Use,
          including investigation of potential violations hereof; (iii) detect,
          prevent, or otherwise address fraud, security or technical issues;
          (iv) respond to user support requests; or (v) protect the rights,
          property or safety of the Company, its Users and the public.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Loyalty Program</h5>
        <p>
          These terms and conditions govern the Company’s Loyalty Program (the
          “Program”). The Program allows you to get points every time you
          perform a qualifying action using the Service. You can use these
          points to obtain rewards.
        </p>

        <p>Eligibility</p>

        <p>
          All registered users of the Service are eligible to participate in the
          Program, if the Community Administrator of the Community(ies) of which
          they are members have elected to participate in the Program. If you
          are uncertain about whether your Community participates in the
          Program, please contact your Community Administrator or
          support@Drund.com.
        </p>

        <p>
          Employees of Drund and its affiliates and partners, and each of their
          immediate family members and individuals living in the same households
          of each of these individuals, are eligible to participate in the
          Program, but these individuals may not participate in any sweepstakes
          or instant wins offered through the Program. We reserve the right to
          determine whether any particular user is eligible to participate in
          the Program.
        </p>

        <p>Enrolling In The Program</p>

        <p>
          If your Community Administrator has elected to participate in the
          Program, you are automatically enrolled.
        </p>

        <p>
          Other than the third party processing fees that may be applicable to
          redeeming certain Rewards or otherwise disclosed to you, there are no
          fees to enroll or participate in the Program, though we reserve the
          right to charge fees in the future for participation in the Program.
          The foregoing does not limit the right of a Community or Group
          Administrator to charge subscription or other fees to be a member of
          their Group or Community.
        </p>

        <p>Accumulating Points</p>

        <p>
          After enrolling, you can start getting points by completing actions
          that allow you to qualify for points, as we indicate from time to
          time. These actions may include:
        </p>

        <ol>
          <li>
            Doing a “check-in” while viewing qualifying media. “Qualifying
            media” includes, without limitation, video, picture, text or
            graphics in the Service for which check-ins are available.
          </li>
          <li>
            Referring new users to Drund or to particular Groups or Communities.
          </li>
          <li>
            Completing certain actions in your Group, Community, or the Service.
          </li>
          <li>
            Completing other actions that we may make available from time to
            time, such as completing surveys, watching a game or other actions.
          </li>
          <li>Redeeming a reward.</li>
        </ol>

        <p>
          We may also place limits on points from time to time in our sole and
          absolute discretion. For example, we may place limits on points you
          may accumulate in a day, or we may place limits on the number of times
          you may accumulate points for performing the same action or
          engagement.
        </p>

        <p>
          We reserve the right to determine, in our sole discretion, whether a
          particular action qualifies for points. We also reserve the right to
          offer different users of the Service different quantities of points
          for the same qualifying action. We also offer certain point-earning
          opportunities, offers or rewards to certain users without offering
          them to all users. For example, we may offer certain users particular
          rewards based on their viewing history, their location or the rewards
          they have chosen in the past.
        </p>

        <p>
          Points that are validly obtained will generally post to your Member
          Account within several hours of a qualifying action, but in some cases
          it may take longer. You can view the points balance in your account
          and your redemption activity by signing into your Member Account.
        </p>

        <p>
          Points obtained or accumulated are and will at all times remain our
          property. Points have no cash or other value, except to obtain
          rewards. You may not sell, purchase (except from Us) or transfer
          points or your Member Account. Points will automatically be forfeited
          in Member Accounts that are terminated or cancelled.
        </p>

        <p>Redemption and Expiration of Points</p>

        <p>
          When you place a redemption order, your Member Account will be reduced
          by the number of points used to acquire the reward. If your reward
          order is cancelled due to an issue with redeeming the reward (such as
          the reward becoming unavailable), we will reinstate your points and
          notify you of the cancelled order.
        </p>

        <p>
          We may also reduce your point total in certain circumstances based on
          your actions. For instance, we may reduce your point balance if you
          call our customer service line in excess of a certain number of times.
        </p>

        <p>
          All unredeemed points will expire if your Member Account is dormant
          for twelve (12) consecutive months, that is, that no qualifying action
          has been taken. Additionally, points need to be redeemed within twelve
          (12) months from when they were obtained or they may be forfeited. We
          reserve the right to issue certain points that will have an earlier or
          shorter expiration date. The points balance in your Member Account
          will be forfeited if your Member Account is terminated for any reason.
          We may also remove points from your account to correct errors. You may
          not be provided with notice of expiration, forfeiture or removal of
          points. You are not entitled to compensation from us or from any other
          entity, when the points in your Member Account expire, if they are
          removed from your account, if your account is terminated, or if they
          are forfeited for any reason.
        </p>

        <p>Membership Tiers</p>

        <p>
          We may establish membership tiers. We may, for example, establish
          membership tiers based on the total annual point balance in your
          Member Account during a calendar year. Different membership tiers may,
          for example, give members the opportunity to obtain additional points,
          obtain exclusive content and obtain exclusive features.
        </p>

        <p>
          We need the flexibility to change our program to provide the best
          experience for our users. Accordingly, if we establish membership
          tiers, we reserve the right to change the membership tiers, the number
          of points required to obtain membership tiers, or terminate the
          membership tiers at any time, in our sole discretion. In addition, we
          may determine the benefits to which a particular membership tier may
          be entitled, and may change those benefits, in our sole discretion.
        </p>

        <p>Rewards</p>

        <p>
          You may redeem your points for merchandise, gift cards, sweepstakes
          entries, instant win plays, offers or other rewards as determined by
          the sponsoring Community or Group Administrator from time to time in
          their sole discretion, or as may be listed on our Rewards Page, while
          supplies last. To redeem points in your Member Account for a reward
          offered by the Company, or your Community , visit the Rewards Page at
          any time. The Rewards Page contains the current list and description
          of available rewards from the Company, or your Community, as well as
          the number of points necessary to obtain each reward. Rewards earned
          in a particular Community may not be redeemable for rewards offered by
          any other Community or the Company, or may not have the same value as
          points earned elsewhere (more points may be required to redeem a
          particular reward that is listed on the Rewards Page).
        </p>
        <p>
          Your community Administrator has the right to change the Rewards Page
          without notice, and we do not guarantee the availability of any
          particular reward. Your administrator also has the right to change the
          number of points required to redeem a particular reward. We may also
          require that you provide us additional information (such as your name,
          address, mobile telephone number or other information) in order to
          redeem a reward, and your receipt of the reward is contingent upon
          your providing us with the information we request. In order to redeem,
          we may also require that you take steps to verify your account, such
          as by sending you a text message to which you must respond, or
          otherwise.
        </p>
        <p>
          A particular reward may be subject to specific terms and conditions,
          such as the expiration date of the Reward, the purchase or other
          requirements to use or deliver the Reward, any return policy
          requirements and any warranties for the reward, or any other
          limitations or restrictions on obtaining, retaining or using the
          reward. Neither we nor the merchant providing the reward will be
          liable if a reward expires prior to redemption for the reward or its
          use. You and the merchant providing the reward are responsible for
          compliance with all laws related to redeeming and receiving the
          reward, including the payment and collection of any applicable
          federal, state, or local taxes. In addition, in order to redeem a
          particular reward, you may have to accept a particular merchant’s
          terms and conditions.
        </p>
        <p>
          In accordance with US tax law, the Company may be required to send you
          and file with the IRS a form 1099 MISC (Miscellaneous Income) for the
          year in which rewards are issued to you. The valuation of rewards and
          point redemptions for tax reporting purposes will be at the Company’s
          sole discretion. You will be responsible for any personal tax
          liability arising out of redemption of points.
        </p>

        <p>
          We may deliver rewards to you electronically (i.e., through the email
          address associated with your account) or by mail. The delivery time
          may vary. Rewards will not be shipped to any address outside of the
          United States.
        </p>

        <p>
          Any redemption of a reward is final. All non-merchandise rewards (such
          as gift cards and certificates) cannot be returned. Merchandise
          rewards may not be exchanged or refunded once a merchant has delivered
          the reward, unless it is defective or has been damaged in transit.
          Merchants, and not the Company, are ultimately responsible for
          replacing any defective or damaged rewards. Refunds, exchanges and
          other issues relating to the reward are governed by the merchant’s
          terms and conditions applicable to the purchase. Redeemed rewards are
          not refundable, exchangeable, or transferable for cash, credit, other
          rewards or points. Neither we nor our participating merchants are
          responsible for replacing lost, stolen, or mutilated rewards,
          including retail or travel certificates, gift certificates, gift
          cards, or merchandise. You may not redeem points for rewards if your
          account has been suspended or terminated for any reason. We reserve
          the right to offer rewards through or transfer rewards to other
          promotional incentive programs offered by Drund and its affiliates or
          partners.
        </p>

        <p>Communications</p>

        <p>
          We may communicate with you regarding any matter related to the
          Program by email or by other electronic communications, including
          private messages using the Service. All electronic communications to
          you sent from us, or on our behalf, will be deemed to be
          communications “in writing” and will be deemed delivered to you no
          later than the earlier of the date actually received or five (5) days
          from the date of posting or dissemination. You agree that by
          participating in the Program we may use your mobile device to contact
          you about your account and to provide promotional and other offers.
          You are responsible for any charges assessed by your mobile carrier
          for communications made by us to your mobile device or other device.
        </p>

        <p>Error Prevention And Other Adjustments</p>

        <p>
          Occasionally we may review the validity of your account, your
          qualifying actions, and your compliance with these Terms of Use. In
          addition we may, but are not required to, review your point balance to
          confirm if any errors have been made in the calculation of your point
          balance. Based on any such review or if we otherwise deem necessary
          (for example, if we believe there have been non-qualifying actions),
          we may authorize an adjustment to the points balance in your account
          and/or membership tier. These adjustments can be positive or negative.
          Details on adjustments made to your account can be found In your
          profile settings. You are responsible for periodically verifying the
          accuracy of your points balance.
        </p>

        <p>Termination Of Your Program Account</p>

        <p>
          You may cancel your account at any time by notifying Customer Service
          at support@Drund.com. Cancellation of your account will be effective
          not later than thirty
        </p>
        <p>
          (30) days after you submit your request, during which period you may
          still obtain points and redeem rewards.
        </p>

        <p>
          We reserve the right, in our sole discretion, to suspend or terminate
          your account, to remove or refuse to award points, or cancel rewards
          associated with your account, for any reason at any time including if
          we have a reasonable belief that: (1) points or rewards were
          improperly obtained or obtained fraudulently (for example, by checking
          into a qualifying broadcast within the last five (5) minutes of the
          program, by checking into multiple qualifying programs at the same
          time or by engaging in other actions that suggest that you are not
          actually watching a qualifying program that you have checked into);
          (2) you have violated these Terms of Use, including, without
          limitation, opening or maintaining multiple accounts or engaging in
          fraudulent activity to obtain or redeem points; or (3) you have
          otherwise abused or tampered with this Program. In addition, we
          reserve the right to suspend or terminate your account if you have not
          completed any actions qualifying for points for twelve (12)
          consecutive months.
        </p>
        <p>
          When an account is cancelled or terminated for any reason, any and all
          point balances, redeemed rewards, or other benefits associated with
          the account, will be forfeited or cancelled effective as of the date
          of such termination.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">Our Proprietary Rights</h5>
        <p>
          Except for your User Content and any Founder IP, the Service and its
          materials, including, without limitation, software, images, text,
          graphics, illustrations, logos, patents, trademarks, service marks,
          copyrights, photographs, audio, videos and music (the “Company IP”),
          and all intellectual property rights related thereto, are the
          exclusive property of the Company and its licensors. Except as
          explicitly provided herein, nothing in this Agreement shall be deemed
          to create a license in or under any such Intellectual Property Rights,
          and you agree not to sell, license, rent, modify, distribute, copy,
          reproduce, transmit, publicly display, publicly perform, publish,
          adapt, edit or create derivative works from any materials or content
          accessible on the Service. Use of the Company IP or materials on the
          Service for any purpose not expressly permitted by this Agreement is
          strictly prohibited.
        </p>
        <p>
          You may choose to or we may invite you to submit comments or ideas
          about the Service, including without limitation about how to improve
          the Service or our products (“Ideas”). By submitting any Ideas, you
          agree that your disclosure is gratuitous, unsolicited and without
          restriction and will not place the Company under any fiduciary or
          other obligation, that we are free to disclose the Ideas on a
          non-confidential basis to anyone or otherwise use the Ideas without
          any additional compensation to you. You acknowledge that, by
          acceptance of your submission, the Company does not waive any rights
          to use similar or related ideas previously known to the Company, or
          developed by its employees, or obtained from sources other than you.
        </p>
        <p>
          Nothing in this Agreement conveys or creates any rights by and between
          you and us with respect to any intellectual property rights of the
          other, whether such intellectual property existed prior to your
          initial use of the Service or was created thereafter. Any
          improvements, changes and developments in the form, delivery and
          operation of the Service, or any Community or Group shall be solely
          and exclusively the property of the Company, regardless of whether
          such improvement was suggested or developed by you.
        </p>
      </section>
      <section className="mb-5">
        <h5 className="mb-3">E-Commerce Features</h5>
        <p>
          The Service may from time to time include features allowing Members to
          make and/or receive payments from or to the Founder, Community
          Administrator and/or third-parties through the Service and/or to
          purchase goods or services from the Founder, Community Administrator,
          other Community Members or third-parties (collectively, the
          “e-Commerce Features”). The Company will receive revenue from all
          payments made through the Service in connection with e-Commerce
          Features based on its agreement with its third-party processing
          partner.
        </p>

        <p>
          In addition, the terms and conditions of use of the third-party
          processing partner will apply to all transactions involving the
          e-Commerce Features. Those terms and conditions are found at{' '}
          <a
            href="https://stripe.com/us/terms"
            target="_blank"
            rel="noreferrer"
          >
            https://stripe.com/us/terms
          </a>{' '}
          and{' '}
          <a href="https://stripe.com/global" target="_blank" rel="noreferrer">
            https://stripe.com/global
          </a>
          .
        </p>

        <h3>Copyright Ownership</h3>

        <p>
          The Service contains copyrighted material, trademarks and other
          proprietary information, including, but not limited to, text,
          software, photos, video, graphics, music and sound, and the entire
          contents of the Service are copyrighted as a collective work under the
          United States copyright laws. Drund Ltd. owns copyright in the
          selection, coordination, arrangement and enhancement of such content,
          as well as in the content original to it. You may not modify, publish,
          transmit, participate in the transfer or sale, create derivative
          works, or in any way exploit, any of the content, in whole or in part.
          You may download copyrighted material for your personal use only.
          Except as otherwise expressly permitted under copyright law, no
          copying, redistribution, retransmission, publication or commercial
          exploitation of downloaded material will be permitted without the
          express permission of Drund Ltd. and the copyright owner. In the event
          of any permitted copying, redistribution or publication of copyrighted
          material, no changes in or deletion of author attribution, trademark
          legend or copyright notice shall be made. You acknowledge that you do
          not acquire any ownership rights by downloading copyrighted material.
        </p>

        <h3>Third Party Content</h3>

        <p>
          Drund Ltd. is a distributor (and not a publisher or creator) of
          content supplied by third parties and users. Any opinions, advice,
          statements, services, offers, or other information or content
          expressed or made available by third parties, including information
          providers or users of the Service, are those of the respective
          author(s) or distributor(s) and not of Drund Ltd. Neither Drund Ltd.
          nor any third-party provider of information guarantees the accuracy,
          completeness, or usefulness of any content, nor its merchantability or
          fitness for any particular purpose. (Refer to the section on
          limitation of liabilities and disclaimers of warranty below.)
        </p>

        <p>
          In many instances, the content available through the Service
          represents the opinions and judgments of the respective user or
          information provider. Drund Ltd. neither endorses nor is responsible
          for the accuracy or reliability of any opinion, advice or statement
          made on the Service by any third party. Under no circumstances will
          Drund Ltd. be responsible or liable, directly or indirectly, for any
          loss or damage caused by your use or reliance on information obtained
          through the Service. Drund Ltd. is not responsible for any actions or
          inaction on your part based on the information that is presented on
          the Service. It is your responsibility to evaluate the accuracy,
          completeness or usefulness of any information, opinion, advice or
          other content available through the Service. Please seek the advice of
          professionals, as appropriate, regarding the evaluation of any
          specific information, opinion, advice or other content.
        </p>

        <h3>Advertisements and Promotions</h3>

        <p>
          Drund Ltd. may run advertisements and promotions from third parties on
          the Service. Your business dealings or correspondence with, or
          participation in promotions of, advertisers other than Drund Ltd., and
          any terms, conditions, warranties or representations associated with
          such dealings, are solely between you and such third party. Drund Ltd.
          is not responsible or liable for any loss or damage of any sort
          incurred as the result of any such dealings or as the result of the
          presence of third-party advertisers on the Service.
        </p>
        <h3>
          Disclaimer of Warranty; Limitation of Liability and Time Limitation
          for Claims
        </h3>

        <p>
          (A) YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK.
          NEITHER DRUND LTD., ITS PRESENT OR FUTURE PARENT(S), SUBSIDIARIES, OR
          RELATED ENTITIES (COLLECTIVELY, &quot;DRUND.&quot;), NOR ANY OF THEIR
          RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR
          LICENSORS WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR
          FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
          OBTAINED FROM USE OF THE SERVICE, OR AS TO THE ACCURACY, RELIABILITY
          OR CONTENT OF ANY INFORMATION, SERVICE, OR MERCHANDISE PROVIDED
          THROUGH THE SERVICE.
        </p>
        <p>
          (B) THE SERVICE, INCLUDING, WITHOUT LIMITATION, ANY DOWNLOADABLE
          SOFTWARE, IS PROVIDED ON AN &quot;AS IS&quot; BASIS WITHOUT WARRANTIES
          OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR
          FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES WHICH
          ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION
          UNDER THE LAWS APPLICABLE TO THESE TERMS OF USE.
        </p>

        <p>
          (C) THE SERVICE MAY OFFER HEALTH, FITNESS, NUTRITIONAL AND OTHER SUCH
          INFORMATION, BUT SUCH INFORMATION IS DESIGNED FOR EDUCATIONAL AND
          INFORMATIONAL PURPOSES ONLY. THE INFORMATION CONTAINED ON THE SERVICE
          DOES NOT AND IS NOT INTENDED TO CONVEY MEDICAL ADVICE AND DOES NOT
          CONSTITUTE THE PRACTICE OF MEDICINE. YOU SHOULD NOT RELY ON THIS
          INFORMATION AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL
          MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. DRUND LTD. IS NOT RESPONSIBLE
          FOR ANY ACTIONS OR INACTION ON A USER&apos;S PART BASED ON THE
          INFORMATION THAT IS PRESENTED IN THE SERVICE.
        </p>
        <p>
          (D) TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT
          SHALL DRUND LTD., BE LIABLE TO YOU FOR ANY PERSONAL INJURY, PROPERTY
          DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF
          DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER AND/OR DEVICE OR
          TECHNOLOGY FAILURE OR MALFUNCTION OR FOR ANY FORM OF DIRECT OR
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE
          DAMAGES BASED ON ANY CAUSES OF ACTION ARISING OUT OF USE OF THE
          SERVICE OR ANY ALLEGED FAILURE OF PERFORMANCE, ERROR, OMISSION,
          INTERRUPTION, DELETION, DEFECT, OR DELAY IN SERVICE, OPERATION, OR
          TRANSMISSION OF THE SERVICE, OR ANY ALLEGED COMPUTER VIRUS,
          COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OF PROPERTY, AND/OR
          UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF OR POSTING OF ANY
          RECORD, CONTENT, OR TECHNOLOGY, PERTAINING TO OR ON THE SERVICE. YOU
          AGREE THAT THIS LIMITATION OF LIABILITY APPLIES WHETHER SUCH
          ALLEGATIONS ARE FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE,
          OR FALL UNDER ANY OTHER CAUSE OF ACTION, REGARDLESS OF THE BASIS UPON
          WHICH LIABILITY IS CLAIMED AND EVEN IF DRUND LTD. HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT LIMITING THE
          GENERALITY OF THE FOREGOING, YOU ALSO SPECIFICALLY ACKNOWLEDGE THAT
          DRUND LTD. IS NOT LIABLE FOR ANY ACTUAL OR ALLEGED DEFAMATORY,
          OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OF THE SERVICE OR ANY
          OTHER THIRD PARTIES.
        </p>
        <p>
          IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE
          LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO
          YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.
        </p>
        <p>
          IN PARTICULAR, IF YOU ARE BASED IN THE EUROPEAN UNION OR UNITED
          KINGDOM, NOTHING IN THESE TERMS LIMITS OR EXCLUDES OUR LIABILITY TO
          YOU FOR: (i) DEATH OR PERSONAL INJURY RESULTING FROM OUR NEGLIGENCE;
          (ii) FRAUD OR FRAUDULENT MISREPRESENTATION BY US; OR (iii) ANY OTHER
          MATTER FOR WHICH WE ARE NOT PERMITTED BY LAW TO EXCLUDE OR LIMIT OUR
          LIABILITY.
        </p>

        <p>
          (E) TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, DRUND LTD.
          DISCLAIMS ANY AND ALL LIABILITY OF ANY KIND FOR ANY UNAUTHORIZED
          ACCESS TO OR USE OF YOUR PERSONALLY IDENTIFIABLE INFORMATION. BY
          ACCESSING THE SERVICE, YOU ACKNOWLEDGE AND AGREE TO DRUND LTD.&apos;S
          DISCLAIMER OF ANY SUCH LIABILITY. IF YOU DO NOT AGREE, YOU SHOULD NOT
          ACCESS OR USE THE SERVICE.
        </p>

        <p>
          (F) TO THE EXTENT PERMITTED BY APPLICABLE LAW, ANY DISPUTE, CLAIM OR
          CONTROVERSY ARISING OUT OF OR RELATING IN ANY WAY TO THE SERVICE OR
          YOUR USE OF THE SERVICE, THESE TERMS OF USE, OR THE RELATIONSHIP
          BETWEEN US, MUST BE COMMENCED WITHIN ONE YEAR OF THE RELEVANT EVENTS.
          A DISPUTE IS COMMENCED IF IT IS FILED IN AN ARBITRATION OR, IF THE
          DISPUTE IS NON-ARBITRABLE, A COURT WITH JURISDICTION, DURING THE
          ONE-YEAR PERIOD. IF YOU OR WE PROVIDE NOTICE OF A DISPUTE UNDER
          SECTION 12 (DISPUTE RESOLUTION), THE ONE-YEAR PERIOD IS TOLLED FOR 60
          DAYS FOLLOWING RECEIPT OF THE NOTICE OF DISPUTE. YOU AND WE EACH
          WAIVE—THAT IS, GIVE UP—THE RIGHT TO PURSUE ANY DISPUTE, CLAIM OR
          CONTROVERSY THAT IS NOT FILED WITHIN ONE YEAR AND ANY RIGHT YOU OR WE
          MAY HAVE HAD TO PURSUE THAT DISPUTE, CLAIM OR CONTROVERSY IN ANY FORUM
          IS PERMANENTLY BARRED.
        </p>

        <h3>Indemnification</h3>

        <p>
          You agree to defend, indemnify and hold harmless Drund Ltd., its
          affiliates and their respective directors, officers, employees and
          agents from and against all claims and expenses, including
          attorneys&apos; fees, arising out of the use of the Service by you or
          your Account. Drund Ltd. reserves the right to take over the exclusive
          defense of any claim for which we are entitled to indemnification
          under this Section. In such event, you shall provide Drund Ltd. with
          such cooperation as is reasonably requested by Drund Ltd.
        </p>

        <h3>Termination</h3>

        <p>
          Drund Ltd. may terminate or suspend these Terms of Use at any time
          without notice to you. Without limiting the foregoing, Drund Ltd.
          shall have the right to immediately terminate Your Account in the
          event of any conduct by you which Drund Ltd., in its sole discretion,
          considers to be unacceptable, or in the event of any breach by you of
          these Terms of Use. The Governing Law and Venue, Severability, Dispute
          Resolution, Arbitration Agreement, Copyrights and Copyright Agent,
          Copyright Ownership, Trademarks, Our Proprietary Rights, E-Commerce
          Features, Drund Streaming Service, Loyalty Program, Disclaimer of
          Warranty, Limitation of Liability, Time Limitation, Advertisements and
          Promotions, Third Party Content, Indemnification and Miscellaneous
          provisions shall survive termination of these Terms of Use.
        </p>

        <h3>Trademarks</h3>

        <p>
          Drund Ltd., its parent, subsidiaries and affiliates, own all rights to
          their logos and trademarks used in connection with the Service. All
          other logos and trademarks appearing on the Service are the property
          of their respective owners.
        </p>

        <h3>Governing Law and Venue</h3>

        <p>
          The content, data, video, and all other material and features on the
          Service are presented for the purpose of providing entertainment, news
          and/or information and/or promoting programs, films, music, games, and
          other products and/or services that are or may become available in the
          United States, its territories, possessions, and protectorates.
        </p>

        <p>
          Any and all disputes, claims and controversies arising out of or in
          connection with your access to, and/or use of the Services, and/or the
          provision of content, services, and/or technology on or through the
          Services shall be governed by and construed exclusively in accordance
          with the laws and decisions of the State of Ohio applicable to
          contracts made, entered into and performed entirely therein, without
          giving effect to its conflict of laws provisions, except to the extent
          that law is inconsistent with or preempted by federal law. To the
          extent that a dispute is not subject to arbitration under the Dispute
          Resolution section of this Agreement below, that action shall be
          brought in the appropriate state or federal court located in
          Cleveland, Ohio; and we both irrevocably consent to the exclusive
          jurisdiction and venue of the state or federal courts in Cuyahoga
          County, Ohio for the adjudication of all non-arbitral claims.
        </p>

        <p>
          If you reside outside of the United States, and to the extent that
          applicable local law prevents certain disputes from being resolved in
          a State of Ohio court, then you can file those disputes in your local
          courts. Likewise, if you reside outside of the United States, and
          applicable local law prevents your local court from applying State of
          Ohio law to resolve these disputes, then these disputes will be
          governed by the applicable local laws of your country, or other place
          of residence.
        </p>

        <h3>Severability</h3>

        <p>
          Except as specified in the Dispute Resolution section below, if any
          provision of this Agreement shall be unlawful, void, or for any reason
          unenforceable, then that provision shall be deemed severable for this
          Agreement and shall not affect the validity and enforceability of any
          remaining provisions. This is the entire agreement between the parties
          relating to the matters contained herein.
        </p>

        <h3>Dispute Resolution</h3>

        <p>Summary:</p>

        <p>
          Our customer-service department can resolve most customer concerns
          quickly and to the customer&apos;s satisfaction. Please contact Drund
          Ltd. at <a href="mailto:support@drund.com"> support@drund.com</a>.{' '}
          <b>
            In the unlikely event that you&apos;re not satisfied with customer
            service&apos;s solution (or if Drund Ltd. has not been able to
            resolve a dispute it has with you after attempting to do so
            informally), we each agree to resolve those disputes through binding
            arbitration or small claims court instead of in courts of general
            jurisdiction.
          </b>
        </p>

        <p>
          Arbitration is more informal than a lawsuit in court. Arbitration uses
          a neutral arbitrator instead of a judge or jury, allows for more
          limited discovery than in court, and is subject to very limited review
          by courts. Unless expressly limited by this Dispute Resolution
          provision, arbitrators can award the same damages and relief that a
          court can award.{' '}
          <b>
            Any arbitration under this Agreement will take place on an
            individual basis; class arbitrations and class actions are not
            permitted.
          </b>{' '}
          In arbitration you are entitled to recover attorneys&apos; fees from
          us to at least the same extent as you would be in court.
        </p>

        <h3>Arbitration Agreement</h3>

        <p>
          <b>(1) Claims Subject to Arbitration:</b> Drund Ltd. and you agree to
          arbitrate <b>all disputes and claims</b> between us, except for claims
          arising from bodily injury or that pertain to enforcing, protecting,
          or the validity of your or our intellectual property rights (or the
          intellectual property rights of any of our licensors, affiliates and
          partners). This agreement to arbitrate is intended to be broadly
          interpreted. It includes, but is not limited to:
        </p>
        <ul>
          <li>
            claims arising out of or relating to any aspect of the relationship
            between us, whether based in contract, tort, fraud,
            misrepresentation or any other statutory or common-law legal theory;
          </li>

          <li>
            claims that arose before this or any prior Agreement (including, but
            not limited to, claims relating to advertising);
          </li>

          <li>
            claims for mental or emotional distress or injury not arising out of
            physical bodily injury;
          </li>
          <li>
            claims that are currently the subject of purported class action
            litigation in which you are not a member of a certified class; and
          </li>

          <li>
            claims that may arise after the termination of this Agreement.
          </li>
        </ul>

        <p>
          References to &quot;Drund Ltd.,&quot; &quot;you&quot;, &quot;we&quot;
          and &quot;us&quot; include our respective subsidiaries, affiliates,
          agents, employees, licensees, licensors, and providers of content as
          of the time your or our claim arises; our respective predecessors in
          interest, successors, and assigns; and all authorized or unauthorized
          users or beneficiaries of Services under this or prior Agreements
          between us. Notwithstanding the foregoing, either party may bring an
          action in small claims court seeking only individualized relief, so
          long as the action remains in that court and is not removed or
          appealed to a court of general jurisdiction. This arbitration
          agreement does not preclude you from bringing issues to the attention
          of federal, state, or local agencies. Such agencies can, if the law
          allows, seek relief against us on your behalf.{' '}
          <b>
            You agree that, by entering into this Agreement, you and we are each
            waiving the right to a trial by jury or to participate in a class
            action.
          </b>{' '}
          This Agreement evidences a transaction in interstate commerce, and
          thus the Federal Arbitration Act governs the interpretation and
          enforcement of this provision. This arbitration provision shall
          survive termination of this Agreement.
        </p>
        <p>
          <b>(2) Pre-Arbitration Notice of Disputes:</b> A party who intends to
          seek arbitration must first send to the other a written Notice of
          Dispute (&quot;Notice&quot;). The Notice to Drund Ltd. should be sent
          by certified mail to: General Counsel, 143 Boardman-Canfield Rd. #361,
          Boardman, Ohio 44512 (&quot;Notice Address&quot;). The Notice must (a)
          describe the nature and basis of the claim or dispute; and (b) set
          forth the specific relief sought (&quot;Demand&quot;).
        </p>

        <p>
          If we and you do not reach an agreement to resolve the claim within 30
          days after the Notice is received, you or we may commence an
          arbitration proceeding. During the arbitration, the amount of any
          settlement offer made by us or you shall not be disclosed to the
          arbitrator until after the arbitrator determines the amount, if any,
          to which you or us is entitled. You may download a form to initiate
          arbitration at:
          adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf.
        </p>
        <p>
          <b>(3) Arbitration Procedure:</b> The arbitration will be governed by
          the Consumer Arbitration Rules (&quot;AAA Rules&quot;) of the American
          Arbitration Association (&quot;AAA&quot;), as modified by this
          arbitration provision, and will be administered by the AAA. (If the
          AAA is unavailable, another arbitration provider shall be selected by
          the parties or by the court.) The AAA Rules are available online at
          www.adr.org, by calling the AAA at
        </p>
        <p>
          1-800-778-7879, or by requesting them in writing at the Notice
          Address. All issues are for the arbitrator to decide, except that
          issues relating to the scope and enforceability of the arbitration
          provision or whether a dispute can or must be brought in arbitration
          are for the court to decide. The arbitrator may consider but shall not
          be bound by rulings in other arbitrations involving different
          customers. Unless we and you agree otherwise, any arbitration hearings
          will take place in the county (or parish) of your billing address. If
          your claim is for $10,000 or less, we agree that you may choose
          whether the arbitration will be conducted solely on the basis of
          documents submitted to the arbitrator, through a telephonic hearing,
          or by an in-person hearing as established by the AAA Rules. If your
          claim exceeds $10,000, the right to a hearing will be determined by
          the AAA Rules.
        </p>
        <p>
          Regardless of the manner in which the arbitration is conducted, the
          arbitrator shall issue a reasoned written decision sufficient to
          explain the essential findings and conclusions on which the award is
          based. Except as provided in subsection (5) below, the arbitrator can
          award the same damages and individualized relief that a court can
          award under applicable law.
        </p>
        <p>
          <b>(4) Arbitration Fees:</b> If we are unable to resolve the Claim
          after the 30-day period specified in Section 2 above and you decide to
          initiate arbitration, you agree to pay the initial filing fee of $200
          (or the amount otherwise required by the AAA Rules), and we agree to
          pay the remaining arbitration initial filing fee and any additional
          deposit required by AAA to initiate your arbitration. We will pay the
          costs of the arbitration proceeding, including the arbitrator’s fees;
          however, other fees, such as attorney’s fees and expenses of travel to
          the arbitration, shall be paid in accordance with the AAA Rules and
          applicable law. We will pay all costs associated with any arbitration
          that we commence.
        </p>
        <p>
          <b>(5) Requirement of Individual Arbitration:</b> The arbitrator may
          award declaratory or injunctive relief only in favor of the individual
          party seeking relief and only to the extent necessary to provide
          relief warranted by that party&apos;s individual claim.{' '}
          <b>
            YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN YOUR OR OUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE, OR PRIVATE ATTORNEY
            GENERAL PROCEEDING.
          </b>{' '}
          Further, unless both you and we agree otherwise, the arbitrator may
          not consolidate more than one person&apos;s claims and may not
          otherwise preside over any form of a representative, class, or private
          attorney general proceeding. If, after exhaustion of all appeals, any
          of these prohibitions on non-individualized declaratory or injunctive
          relief; class, representative, and private attorney general claims;
          and consolidation are found to be unenforceable with respect to a
          particular claim or with respect to a particular request for relief
          (such as a request for injunctive relief sought with respect to a
          particular claim), then that claim or request for relief shall be
          severed, and all other claims and requests for relief shall be
          arbitrated.
        </p>

        <p>
          <b>(6) Future Changes to Arbitration Provision:</b> Notwithstanding
          any provision in this Agreement to the contrary, we agree that if we
          make any future change to this arbitration provision (other than a
          change to the Notice Address), you may reject any such change by
          sending us written notice within 30 days of the change to the
          arbitration Notice Address provided above. By rejecting any future
          change, you are agreeing that you will arbitrate any dispute between
          us in accordance with the language of this provision.
        </p>

        <p>
          <b>(7) Local Law Requirements:</b> If you reside outside of the United
          States, and to the extent that applicable local law prevents certain
          disputes from being arbitrated in accordance with this Arbitration
          Agreement, then you can file those disputes in your local courts.
          Likewise, if you reside outside of the United States, and applicable
          local law prevents your local court from applying State of Ohio law to
          resolve these disputes, then these disputes will be governed by the
          applicable local laws of your country, or other place of residence.
        </p>

        <h3>Miscellaneous</h3>

        <p>
          These Terms of Use and any operating rules for the Service established
          by Drund Ltd. constitute the entire agreement of the parties with
          respect to the subject matter hereof and supersede all previous
          written or oral agreements between the parties with respect to such
          subject matter. The provisions of these Terms of Use are for the
          benefit of Drund Ltd., its parent, subsidiaries, other affiliates and
          its third party content providers and licensors and each shall have
          the right to assert and enforce such provisions directly or on its own
          behalf. If you access the Service, including its Interactive Areas,
          from any location other than the United States, you accept full
          responsibility for compliance with all local laws. You are also
          subject to United States export controls and are responsible for any
          violations of United States embargoes or other federal rules and
          regulations restricting exports. No waiver by either party of any
          breach or default hereunder shall be deemed to be a waiver of any
          preceding or subsequent breach or default. If any part of these Terms
          of Use is found by a court of competent jurisdiction to be invalid or
          unenforceable, it will be replaced with language reflecting the
          original purpose in a valid and enforceable manner. The enforceable
          sections of these Terms of Use will remain binding upon the parties.
          The section headings used herein are for convenience only and shall
          not be given any legal import.
        </p>
        <p>
          Neither Drund Ltd. nor you shall be liable for damages or for delays
          or failures in performance resulting from acts or occurrences beyond
          their reasonable control, including, without limitation: fire,
          lightning, explosion, power surge or failure, water, acts of God, war,
          terrorism, revolution, civil commotion or acts of civil or military
          authorities or public enemies: any law, order, regulation, ordinance,
          or requirement of any government or legal body or any representative
          of any such government or legal body; or labor unrest, including
          without limitation, strikes, slowdowns, picketing, or boycotts;
          inability to secure raw materials, transportation facilities, fuel or
          energy shortages, or acts or omissions of other common carriers.
        </p>

        <h3>Copyrights and Copyright Agent</h3>

        <p>
          Drund Ltd. respects the rights of all copyright holders and in this
          regard, Drund Ltd. has adopted and implemented a policy that provides
          for the termination in appropriate circumstances of users and account
          holders who infringe the rights of copyright holders. If you believe
          that your work has been copied in a way that constitutes copyright
          infringement, please provide Drund Ltd.&apos;s Copyright Agent the
          following information required by the Online Copyright Infringement
          Liability Limitation Act of the Digital Millennium Copyright Act, 17
          U.S.C. 512:
        </p>
        <ol>
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed;
          </li>

          <li>
            Identification of the copyright work claimed to have been infringed,
            or, if multiple copyrighted works at a single online site are
            covered by a single notification, a representative list of such
            works at that site;
          </li>

          <li>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit us to locate the material;
          </li>

          <li>
            Information reasonably sufficient to permit us to contact the
            complaining party;
          </li>

          <li>
            A statement that the complaining party has a good-faith belief that
            use of the material in the manner complained of is not authorized by
            the copyright owner, its agent, or the law; and
          </li>

          <li>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that the complaining party is
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
          </li>
        </ol>

        <p>
          For copyright inquiries under the Digital Millennium Copyright Act
          please contact:
        </p>
        <h3>DRUND LTD.</h3>
        <h3>143 Boardman-Canfield Rd #361</h3>
        <h3>Boardman, Ohio 44512</h3>
        <h3>
          Email: <a href="mailto:support@drund.com">support@Drund.com</a>
        </h3>
        <p>
          For web posting, reprint, transcript for DRUND LTD. material, please
          contact <a href="mailto:support@drund.com">support@Drund.com</a>
        </p>
        <p>
          For any questions or requests other than copyright issues or licensing
          requests, please contact. Support@Drund Ltd..com.
        </p>
        <p>
          <i>These Terms of Use were last updated on May 23, 2020.</i>
        </p>
      </section>
    </React.Fragment>
  );
}
