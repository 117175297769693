import {useState, useEffect} from 'react';

export const useManageCookies = (scrollRef) => {
  const [manageData, setManageData] = useState({
    scrolledBottom: false,
    scrollRequiredError: false
  });

  useEffect(() => {
    if (!manageData.scrolledBottom) {
      const onScroll = (e) => {
        if (
          e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
          20
        ) {
          setManageData((prevValues) => {
            return {...prevValues, scrolledBottom: true};
          });
        }
      };
      const target = scrollRef.current;
      target.addEventListener('scroll', onScroll);

      return () => {
        target.removeEventListener('scroll', onScroll);
      };
    }
    return;
  });

  const submitError = (e) => {
    e.preventDefault();

    setManageData((prevValues) => {
      return {...prevValues, scrollRequiredError: true};
    });
  };

  return {
    manageData,
    submitError
  };
};
