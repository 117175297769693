import React from 'react';

import './Header.css';
import {useHeader} from './useHeader.js';
import {useClickOutside} from '../../../../hooks/useClickOutside.js';
import SentryTest from '../../../../components/SentryTest/SentryTest.js';

export default function Header({mobile_preview, nav, membership, environment}) {
  const {dropdownOpen, setDropdownOpen, toggleDropdown} = useHeader();

  let navRef = useClickOutside(() => {
    setDropdownOpen(false);
  });

  if (mobile_preview) {
    return null;
  }

  let logoImage = nav.logo_image ? (
    <a href={nav.urls.home} className="d-flex align-items-center mx-2 p-lg-0">
      <img className="community-icon" src={nav.logo_image} />
    </a>
  ) : (
    <a
      href={nav.urls.home}
      className="drund_logo p-2 d-flex align-items-center"
    >
      drund
    </a>
  );

  let membershipJsx = membership.display_name ? (
    <li className="nav-item m-0">
      <a className="h7 d-flex align-items-center p-4 w-100" href="/app/">
        <img
          src={membership.avatar}
          className="profile-image rounded-circle mr-3"
          width="48"
          height="48"
        />
        <span>{membership.display_name}</span>
      </a>
    </li>
  ) : (
    ''
  );

  let navItemsJsx = nav.pages.map((navItem) => {
    if (
      navItem.title.toLowerCase() == 'login' ||
      navItem.title.toLowerCase() == 'register'
    ) {
      // bail
      return;
    }

    let subNavItems = '';
    if (navItem.sub_pages?.length > 0) {
      subNavItems = navItem.sub_pages.map((subNavItem) => {
        return (
          <li key={subNavItem.id} className="nav-item nested m-0">
            <span className="d-flex align-items-center justify-content-between">
              <a className="h7 w-100 p-3" href={subNavItem.link}>
                <span className="ellipsis ml-4">{subNavItem.title}</span>
              </a>
            </span>
          </li>
        );
      });
    }

    return (
      <div key={navItem.id}>
        <li
          className={'nav-item m-0 ' + (navItem.current_page ? 'active' : '')}
        >
          <a
            className="h7 w-100 p-3 d-flex align-items-center justify-content-between"
            href={navItem.link}
          >
            <span className="d-block ellipsis">{navItem.title}</span>
            {navItem.sub_pages?.length > 0 ? (
              <svg
                viewBox="0 0 32 32"
                className="dropdown_svg svg-icon svg-icon-24x24 icon-ic_chevron_down flex-shrink-0"
              >
                <use href="#icon-ic_chevron_down"></use>
              </svg>
            ) : (
              ''
            )}
          </a>
        </li>
        {subNavItems}
      </div>
    );
  });

  if (environment.toLowerCase().trim() == 'development') {
    navItemsJsx.push(<SentryTest key={-1} />);
  }
  return (
    <div className="navbar">
      <nav className="w-100 d-flex justify-content-between align-items-center position-relative">
        {logoImage}

        <div ref={navRef} className="d-flex align-items-center">
          <div
            onClick={toggleDropdown}
            className={
              'd-flex dropdown_button p-2' + (dropdownOpen ? ' active' : '')
            }
          >
            <svg
              viewBox="0 0 32 32"
              className="close_button svg-icon svg-icon-24x24 icon-ic_close"
            >
              <use href="#icon-ic_close"></use>
            </svg>
            <svg
              viewBox="0 0 32 32"
              className="menu_button svg-icon svg-icon-24x24 icon-ic_menu"
            >
              <use href="#icon-ic_menu"></use>
            </svg>
          </div>
          <ul
            className={
              'dropdown_links position-absolute m-0 p-0' +
              (dropdownOpen ? '' : ' d-none')
            }
          >
            {membershipJsx}

            {navItemsJsx}

            {membership.id ? (
              <li className="nav-item m-0 border-0">
                <span className="d-flex align-items-center">
                  <a className="h7 w-100 p-3" href="/logout/">
                    <span className="ellipsis">Logout</span>
                  </a>
                </span>
              </li>
            ) : (
              <li className="nav-item m-0 border-0">
                <span className="d-flex align-items-center">
                  <a className="mr-2 pl-3 py-3" href="/login/">
                    <svg
                      viewBox="0 0 32 32"
                      className="svg-icon svg-icon-24x24 icon-ic_power login_power_icon flex-shrink-0"
                    >
                      <use href="#icon-ic_power"></use>
                    </svg>
                  </a>
                  <a
                    className="ml-1 h7 w-100 pr-4 py-3"
                    href="/login/?next=feed"
                  >
                    <span className="ellipsis">Team Login</span>
                  </a>
                </span>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
}
