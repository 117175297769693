import {useRef, useEffect} from 'react';

// fantastic explanation of this super reuseable custom hook
// https://www.youtube.com/watch?v=eWO1b6EoCnQ
export const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let handleClick = (event) => {
      if (!handler) return; // bail

      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return domNode;
};
