import React from 'react';

import {useContactForm} from './useContactForm.js';

export default function ContactForm({onClose, urls}) {
  const {inputs, handleInputChange, handleSubmit} = useContactForm(
    onClose,
    urls
  );

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      <input
        className="mb-4"
        onChange={handleInputChange}
        value={inputs.full_name}
        required
        name="full_name"
        placeholder="Name *"
      />
      <input
        className="mb-4"
        onChange={handleInputChange}
        value={inputs.email_address}
        required
        name="email_address"
        placeholder="Email Address *"
      />
      <input
        className="mb-4"
        onChange={handleInputChange}
        value={inputs.phone_number}
        name="phone_number"
        placeholder="Phone Number"
      />
      <input
        className="mb-4"
        onChange={handleInputChange}
        value={inputs.message}
        required
        name="message"
        placeholder="Description *"
      />
      <div className="mb-4 black description_full_opacity">
        *Required field.
      </div>
      <div className="submit-area d-flex justify-content-end">
        <button
          type="submit"
          onClick={handleSubmit}
          className="theme-3_button contact_submit text-uppercase h6 d-flex align-items-center m-0"
        >
          <span className="mr-2 d-flex align-items-center">Submit</span>
          <svg
            viewBox="0 0 32 32"
            className="svg-icon svg-icon-24x24 svg-icon-dark-gray icon-ic_arrow_right"
          >
            <use href="#icon-ic_arrow_right"></use>
          </svg>
        </button>
      </div>
    </form>
  );
}
