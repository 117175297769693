import React, {useRef} from 'react';
import Terms from '../../components/ManageCookies/Terms.js';

import {useManageCookies} from './useManageCookies.js';

export default function ManageCookies(props) {
  const scrollRef = useRef(null);

  const {manageData, submitError} = useManageCookies(scrollRef);

  return (
    <div className="cookie_modal manage_cookies_modal">
      <div className="cookie_content_area m-4">
        <div className="cookie_header d-flex align-items-center justify-content-between mb-4">
          <h6 className="text-uppercase mb-0">Manage Cookies</h6>
          <a href="#" onClick={props.closeModal} className="close_modal">
            <svg
              viewBox="0 0 32 32"
              className="mr-0 svg-icon svg-icon-24x24 svg-icon-dark-gray icon-ic_close"
            >
              <use href="#icon-ic_close"></use>
            </svg>
          </a>
        </div>
        <div className="cookie_message mb-4">
          <div className="mb-4">
            All the cookies used on this website are strictly necessary. To
            submit this form please read the terms of use shown below.
          </div>
          <div className="mb-4">
            <div className="d-flex align-items-center mb-2">
              <svg
                viewBox="0 0 32 32"
                className="flex-shrink-0 mr-3 checkmark_icon svg-icon-black svg-icon svg-icon-24x24 icon-ic_checkmark_20dp"
              >
                <use href="#icon-ic_checkmark_20dp"></use>
              </svg>
              Allow you to navigate back and forth between pages without losing
              previous actions from the same session.
            </div>
            <div className="d-flex align-items-center mb-2">
              <svg
                viewBox="0 0 32 32"
                className="flex-shrink-0 mr-3 checkmark_icon svg-icon-black svg-icon svg-icon-24x24 icon-ic_checkmark_20dp"
              >
                <use href="#icon-ic_checkmark_20dp"></use>
              </svg>
              Remember your previous actions such as your previous cookie
              consent choice.
            </div>
            <div className="d-flex align-items-center">
              <svg
                viewBox="0 0 32 32"
                className="flex-shrink-0 mr-3 checkmark_icon svg-icon-black svg-icon svg-icon-24x24 icon-ic_checkmark_20dp"
              >
                <use href="#icon-ic_checkmark_20dp"></use>
              </svg>
              Allows us to store the color of the highlighted text.
            </div>
          </div>
          <div ref={scrollRef} className="cookie_terms_area p-3 mb-4">
            <Terms />
          </div>
        </div>
        {manageData.scrollRequiredError ? (
          <div className="terms_scroll_required">
            * You must read terms of use.
          </div>
        ) : (
          ''
        )}

        <div className="cookie_button_area d-flex justify-content-end">
          <a
            href="#"
            onClick={
              manageData.scrolledBottom ? props.handleAcceptCookie : submitError
            }
            className={
              'manage_cookies_close cookie_button theme-3_button d-flex align-items-center text-uppercase ' +
              (manageData.scrolledBottom ? '' : 'disabled')
            }
          >
            <span className="h6 black mb-0 mr-1">Save and Close</span>
            <svg
              viewBox="0 0 32 32"
              className="svg-icon svg-icon-24x24 cookie_button_arrow icon-ic_arrow_right"
            >
              <use href="#icon-ic_arrow_right"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
