import {useEffect} from 'react';

export const useCustomPageJS = (customPageContent, toggleModal) => {
  useEffect(() => {
    // dangerously set innerhtml doesn't run contained js
    // if a script tag with id customScript exists
    // run the script with window.eval
    if (document.getElementById('customScript') != null) {
      const scriptDom = document.getElementById('customScript');
      window.eval(scriptDom.innerHTML);
      // ensure script is only ever ran once
      scriptDom.remove();
    }

    // allow custom content to open the modal with the class contact_trigger
    const clickCallback = (e) => {
      if (e.target.classList.contains('contact_trigger')) {
        e.preventDefault();
        toggleModal(true);
      }
    };

    const target = customPageContent.current;
    target.addEventListener('click', clickCallback);

    return () => {
      target.removeEventListener('scroll', clickCallback);
    };
  });
};
