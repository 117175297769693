import {useState} from 'react';
import Cookies from 'js-cookie';

export const useCookieModal = (color, community_name) => {
  const checkCookieStatus = () => {
    return !!Cookies.get('drund_' + community_name + '_accept_cookies');
  };

  const [cookieModalData, setCookieModalData] = useState({
    open: !checkCookieStatus(),
    genericModal: true
  });

  const handleAcceptCookie = (e) => {
    e.preventDefault();

    Cookies.set('drund_' + community_name + '_accept_cookies', 1);
    sessionStorage.setItem('drundpress_theme', color);
    setCookieModalData((prevValues) => {
      return {...prevValues, open: false};
    });
  };

  const changeModalContent = (e) => {
    e.preventDefault();

    setCookieModalData((cookieModalData) => {
      return {...cookieModalData, genericModal: false};
    });
  };

  const closeModal = (e) => {
    e.preventDefault();

    setCookieModalData((cookieModalData) => {
      return {...cookieModalData, open: false};
    });
  };

  return {
    cookieModalData,
    handleAcceptCookie,
    changeModalContent,
    closeModal
  };
};
