import {useState} from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

export const useContactForm = (onClose, urls) => {
  const [inputs, setInputs] = useState({
    full_name: '',
    email_address: '',
    phone_number: '',
    message: ''
  });

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();

      // head request to get csrf token back as a cookie
      axios
        .head('/contact/')
        .then(() => {
          let formData = new FormData();
          for (let key in inputs) {
            formData.append(key, inputs[key]);
          }

          return axios.post(urls.contact, formData, {
            headers: {
              'X-CSRFTOKEN': Cookies.get('csrftoken')
            }
          });
        })
        .then(() => {
          /*global toastr*/
          /*eslint no-undef: "error"*/
          toastr.success('Thank you for your message.');
        })
        .catch(() => {
          toastr.error('Error please try again later.');
        })
        .then(() => {
          onClose();
        });
    }
  };

  const handleInputChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
};
