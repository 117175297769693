import React from 'react';
import ReactDom from 'react-dom';

import GenericCookies from '../../components/GenericCookies/GenericCookies.js';
import ManageCookies from '../../components/ManageCookies/ManageCookies.js';

import './Cookies.css';

import {useCookieModal} from './useCookieModal.js';

const modalRoot = document.getElementById('modal-root');

export default function CookieModal({color, community_name}) {
  const {cookieModalData, handleAcceptCookie, changeModalContent, closeModal} =
    useCookieModal(color, community_name);

  if (!cookieModalData.open) {
    return null;
  }

  return ReactDom.createPortal(
    <div className={'cookie_modal ' + color}>
      {cookieModalData.genericModal ? (
        <GenericCookies
          handleAcceptCookie={handleAcceptCookie}
          changeModalContent={changeModalContent}
        />
      ) : (
        <ManageCookies
          handleAcceptCookie={handleAcceptCookie}
          closeModal={closeModal}
        />
      )}
    </div>,
    modalRoot
  );
}
