import React, {useRef} from 'react';
import {createRoot} from 'react-dom/client';

import '../../css/Index.css';

import Header from '../../components/Header/Header.js';
import HeroImage from '../../../../components/HeroImage/HeroImage.js';
import Footer from '../../components/Footer/Footer.js';
import CookieModal from '../../components/CookieModal/CookieModal.js';
import Modal from '../../components/Modal/Modal';
import ContactForm from '../../components/ContactForm/ContactForm.js';

import {useCustomPageJS} from '../../../../hooks/useCustomPageJS.js';
import {useColor} from './useColor.js';
import {useModal} from '../../../../hooks/useModal';

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

Sentry.init({
  dsn: 'https://44501001aee04a2c84d64013a75c7326@o153364.ingest.sentry.io/6311836',
  integrations: [new BrowserTracing()],

  tracesSampleRate: 1.0
});

function CustomPage(props) {
  const {color} = useColor(props.community.name);
  const {modalOpen, toggleModal} = useModal();

  const customPageContent = useRef(null);
  useCustomPageJS(customPageContent, toggleModal);

  return (
    <div className={color}>
      <Header
        mobile_preview={props.mobile_preview}
        nav={props.nav}
        membership={props.membership}
        environment={props.environment}
      />
      <HeroImage hero_image={props.hero_image} />

      <div
        id="container"
        className={'container pb-5 ' + (props.mobile_preview ? '' : 'pt-4')}
      >
        <div id="col1" className="row justify-content-center">
          <div className="mb-8 col-md-12 col-lg-8">
            {/*
              Insert custom page content into page,
              dangerouslySetInnerHtml allows css and script tags
              to be inserted and not escaped, it won't run inserted
              js and it will need to useCustomPageJS to run it.
            */}
            <div
              ref={customPageContent}
              className="mw-100 text-break"
              dangerouslySetInnerHTML={{__html: props.pages}}
            ></div>
          </div>
        </div>
      </div>

      <Footer
        mobile_preview={props.mobile_preview}
        footer={props.footer}
        community_name={props.community.name}
        toggleModal={toggleModal}
      />

      <CookieModal color={color} community_name={props.community.name} />

      <Modal
        open={modalOpen}
        color={color}
        onClose={() => toggleModal(false)}
        title="Contact Us"
      >
        <ContactForm
          onClose={() => toggleModal(false)}
          urls={props.footer.urls}
        />
      </Modal>
    </div>
  );
}

createRoot(document.getElementById('custom_page_container')).render(
  React.createElement(CustomPage, window.props)
);
