import React from 'react';

export default function HeroImage({hero_image}) {
  if (!hero_image.show) {
    return null;
  }

  return (
    <a
      href={hero_image.link ? hero_image.link : '#'}
      className="text-decoration-none"
    >
      <div
        className={
          'd-flex align-items-center overlay justify-content-center ' +
          (hero_image.title ? 'hero-content-with-text' : 'hero-content')
        }
        style={{backgroundImage: `url(${hero_image.url})`}}
      >
        {hero_image.title && (
          <h1 id="hero-text" className="px-2">
            {hero_image.title}
          </h1>
        )}
      </div>
    </a>
  );
}
