import {useState} from 'react';

export const useModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = (newValue) => {
    // later document body can be in react dom
    document.body.classList.toggle('fixed-body', newValue);

    setModalOpen(() => {
      return newValue;
    });
  };

  return {
    modalOpen,
    toggleModal
  };
};
